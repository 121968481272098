import React, { useEffect, useState } from 'react'
import { Form, message } from 'antd'

import SEO from '../blocks/layout/seo'
import AppContainer from '../blocks/layout/appContainer'
import { Firebase } from '../config/firebase'
import axios from 'axios'
import { navigate } from 'gatsby'
import { Button, Input, Note, Select } from '@geist-ui/core'
import { FiPlay } from 'react-icons/fi'
import { randomString, reservedKeyword } from '../config/functions'

const Create = () => {
  const [validateStatus, setValidateStatus] = useState(true)
  const [loading, setLoading] = useState(false)

  async function fetchMyAPI(userObj) {
    if (userObj) {
      const { uid, email } = userObj
      let response = await axios.post(
        `${process.env.GATSBY_API_BASE_URL}/regions`,
        { uid, email },
      )

      console.log('done')
    }
  }

  useEffect(() => {
    const unsubscribe = Firebase.auth().onAuthStateChanged(fetchMyAPI)

    // unsubscribe to the listener when unmounting
    return () => unsubscribe()
  }, [])

  const createNewProject = values => {
    const projectName = values.projectName.toLowerCase()

    if (reservedKeyword(projectName)) {
      setLoading(true)

      Firebase.auth().onAuthStateChanged(function (user) {
        values.uid = user.uid
        values.email = user.email
        values.projectHostName = `${process.env.GATSBY_API_HTTP}://${projectName}.${process.env.GATSBY_API_HOST_URL}`
        const projectUniqueId = randomString(16, 'aA#')
        values.projectUniqueId = projectUniqueId

        var data = JSON.stringify(values)

        var config = {
          method: 'post',
          url: `${process.env.GATSBY_API_BASE_URL}/createProject`,
          headers: {
            'Content-Type': 'application/json',
          },
          data: data,
        }

        axios(config)
          .then(function (response) {
            setLoading(false)
            const { projectUniqueId } = response.data
            if (projectUniqueId === undefined) {
              message.info(
                'The project name is not available, choose different name',
              )
            } else {
              navigate(`/projects/${projectUniqueId}`)
            }
          })
          .catch(function (error) {
            setLoading(false)
          })
      })
    } else {
      message.info('Project name is not available')
      setLoading(false)
    }
  }
  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo)
  }
  return (
    <>
      <SEO title="One place for all your forms — Quick API" />
      <AppContainer menuKey="1">
        <h2>Create a new project</h2>
        <p>Choose a subdomain name for your product.</p>

        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={createNewProject}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item
            label="Subdomain"
            name="projectName"
            validateStatus={validateStatus}
            rules={[
              {
                required: true,
                max: 20,
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (/^[A-Za-z0-9_]+$/.test(value)) {
                    setValidateStatus('')
                    return Promise.resolve()
                  }
                  setValidateStatus('error')
                },
              }),
            ]}
          >
            <Input
              labelRight=".quickapi.io"
              pattern="[A-Za-z0-9_]+"
              minLength="2"
              autoCapitalize="none"
            />
          </Form.Item>

          <Note>This will take 5-minutes.</Note>
          <br />

          <Form.Item>
            <Button
              type="secondary"
              htmlType="submit"
              loading={loading}
              icon={<FiPlay />}
              width="200px"
            >
              Start new project
            </Button>
          </Form.Item>
        </Form>
      </AppContainer>
    </>
  )
}

export default Create
