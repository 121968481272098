var names = require('./names.json')

export const randomString = function (length, chars) {
  let mask = ''
  if (chars.indexOf('a') > -1) mask += 'abcdefghijklmnopqrstuvwxyz'
  if (chars.indexOf('A') > -1) mask += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  if (chars.indexOf('#') > -1) mask += '0123456789'
  if (chars.indexOf('!') > -1) mask += '~`!@#$%^&*()_+-={}[]:";\'<>?,./|\\'
  let result = ''
  for (let i = length; i > 0; --i)
    result += mask[Math.round(Math.random() * (mask.length - 1))]
  return result
}

export const abbreviateNumber = function (value) {
  var newValue = value
  if (value >= 1000) {
    var suffixes = ['', 'k', 'm', 'b', 't']
    var suffixNum = Math.floor(('' + value).length / 3)
    var shortValue = ''
    for (var precision = 2; precision >= 1; precision--) {
      shortValue = parseFloat(
        (suffixNum != 0
          ? value / Math.pow(1000, suffixNum)
          : value
        ).toPrecision(precision),
      )
      var dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g, '')
      if (dotLessShortValue.length <= 2) {
        break
      }
    }
    if (shortValue % 1 != 0) shortValue = shortValue.toFixed(1)
    newValue = shortValue + suffixes[suffixNum]
  }
  return newValue
}

export const formatBytes = function (bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export const reservedKeyword = function (name) {
  const isReserved = names.findIndex(x => x === name)
  if (isReserved > -1) return false
  return true
}

export const formatJSON = function (val = {}) {
  try {
    const res = JSON.parse(val)
    return JSON.stringify(res, null, 2)
  } catch {
    const errorJson = {
      error: `非法返回${val}`,
    }
    return JSON.stringify(errorJson, null, 2)
  }
}

export const removeByAttr = function (arr, attr, value) {
  var i = arr.length
  while (i--) {
    if (
      arr[i] &&
      arr[i].hasOwnProperty(attr) &&
      arguments.length > 2 &&
      arr[i][attr] === value
    ) {
      arr.splice(i, 1)
    }
  }
  return arr
}

export const groupTasks = function (tasks) {
  const result = tasks.reduce(function (r, a) {
    r[a.formObj.state] = r[a.formObj.state] || []
    r[a.formObj.state].push(a)
    return r
  }, Object.create(null))

  return result
}

export const sortPricingArrayObj = function (ArrayList, keyName) {
  return ArrayList.sort((a, b) => a.pricing[keyName] - b.pricing[keyName])
}
